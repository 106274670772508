import * as React from "react"
import SEO from "../components/SEO/SEO";
import { Layout } from "../components/layout/layout";
import { PageSection } from "../components/page-section/page-section";
import image1 from "../images/vanton-1-small.png";
import image2 from "../images/vanton-2-small.png";
import image3 from "../images/vanton-3-small.png";
import image4 from "../images/vanton-4-small.png";
import image5 from "../images/vanton-5-small.png";
import image6 from "../images/vanton-6-small.png";
import image1WP from "../images/vanton-1-small.webp";
import image2WP from "../images/vanton-2-small.webp";
import image3WP from "../images/vanton-3-small.webp";
import image4WP from "../images/vanton-4-small.webp";
import image5WP from "../images/vanton-5-small.webp";
import image6WP from "../images/vanton-6-small.webp";
import largeImage1 from "../images/vanton-1.png";
import largeImage2 from "../images/vanton-2.png";
import largeImage3 from "../images/vanton-3.png";
import largeImage4 from "../images/vanton-4.png";
import largeImage5 from "../images/vanton-5.png";
import largeImage6 from "../images/vanton-6.png";
import largeImage1WP from "../images/vanton-1.webp";
import largeImage2WP from "../images/vanton-2.webp";
import largeImage3WP from "../images/vanton-3.webp";
import largeImage4WP from "../images/vanton-4.webp";
import largeImage5WP from "../images/vanton-5.webp";
import largeImage6WP from "../images/vanton-6.webp";
import awardLogo from "../images/vanton-award-logo.png";

import "./vanton.scss";
import { Arrow } from "../components/arrow/arrow";
import FormContainer from "../components/form-container/form-container";

const VantonPage = () => {
  return (
    <main>
      <SEO
        title="Vanton Yachts"
        pathname="/vanton"
        description="Miamiyachting is now a proud partner and official US dealer for Vanton Yachts"
        keywords={["Vanton", "yacht", "luxury", "Dutch", "Netherland", "high-quality", "sea", "lakes", "rivers", "quicksteel", "space", "safety", "comfort", "handcrafted"]}
      />
      <Layout page="vanton">
        <div className="my-vanton">
          <div className="my-vanton-space" />
          <Arrow alignBottom={false} url="" />
          <PageSection id="about-vanton-yachts" image={image1} imageWP={image1WP} largeImage={largeImage1} largeImageWP={largeImage1WP} title="About Vanton Yachts" isReverse={true}>
            <p className="my-vanton-section">Vanton Yachts is a Dutch yacht builder that develops and produces high-quality steel and aluminum motor yachts of 40, 45 and 50 feet. The company was founded in 2021 by Ton van Rutten. Ton has a background as an architect. The search for spatial efficiency, designing a practical interior and applying high-quality aesthetics are tailor-made for him. And this is immediately reflected in the balanced and tasteful design of the first Vanton. The Vanton 45 Cabin Cruiser, an excellently designed space wonder.</p>
            <p className="my-vanton-section">In order to bring motor yachts to the market that meet the high standards of Dutch quality requirements, Vanton Yachts works closely with renowned agencies for naval design and technical development. Partners who guarantee high-quality sailing characteristics and have a proven track record in their field.</p>
            <p className="my-vanton-section">Vanton Yachts has now launched the successful Vanton Quicksteel® 45 Cabin Cruiser. A striking appearance. With this exceptionally innovative yacht, Vanton Yachts has already received several prestigious nominations, including the ‘Dutch Powerboat of the Year 2023’ and the ‘European Powerboat of the Year 2024’ in the ‘LongeRange’ category.</p>
            <div className="my-vanton-award">
              <a className="my-vanton__link" href="https://www.vanton-yachts.com">Visit Vanton website</a>
              <img src={awardLogo} alt="Award Logo" />
            </div>
            
          </PageSection>
          <Arrow alignBottom={false} url="" />
          <PageSection id="for-adventurous-frequent-sailors" image={image2} imageWP={image2WP} largeImage={largeImage2} largeImageWP={largeImage2WP} title="For adventurous frequent sailors">
            <p className="my-vanton-section">Vanton’s yachts are aimed at adventurous frequent sailors, looking to explore America coastal waters, lakes and rivers. People who often like to travel for longer periods of time and for whom exceptional comfort is key. This philosophy has been perfectly captured in a timeless motoryacht with lots of interior space, luxury and safety. This is translated into timeless motor yachts with exceptional interior space, luxury and safety. The agile motor yachts from Vanton Yachts are easy and safe to cruise. The low clearance of 2.75 m¹ ensures that you will encounter few obstacles on the way to your destination.</p>
            <p className="my-vanton-section">With the Quicksteel® DPH (Dual Purpose Hull), specially designed for Vanton Yachts, speeds of up to 17 knots are achievable, depending on the motorization. In addition to excellent sailing characteristics, the design also guarantees efficient fuel use. Made of aluminum, even higher speeds are possible.</p>
            <p className="my-vanton-section">With a view to the future, all yachts are also available in hybrid versions.</p>
          </PageSection>
          <Arrow alignBottom={false} url="" />
          <PageSection id="quicksteel" image={image3} imageWP={image3WP} largeImage={largeImage3} largeImageWP={largeImage3WP} title="Quicksteel®"  isReverse={true}>
            <p className="my-vanton-section">Vanton Yachts are specially designed for safe trips on rivers, lakes and along coasts. With the unique Quicksteel® DPH with the special spray rails (X-Spray) and characteristic deep axe bow, Vanton Yachts have sublime sailing characteristics at all speeds.</p>
            <p className="my-vanton-section">With the Quicksteel® DPH efficient fuel consumption is guaranteed and higher speeds, well above hull speed, are also easily achieved. In addition, our yachts steer very directly due to the unique hull shape and are easy and precise to maneuver. The X-Spray operates at higher speeds and reduces noise at low speeds. This contributes to the comfort of the already exceptionally quiet interior. The deep axe bow easily cuts through the waves. This gives our yachts the stability required for superior sailing characteristics.</p>
          </PageSection>
          <PageSection id="most-interior-space-in-its-class" image={image4} imageWP={image4WP} largeImage={largeImage4} largeImageWP={largeImage4WP} title="Most interior space in its class">
            <p className="my-vanton-section">At Vanton we’re convinced that space is synonymous with comfort. Everything has been done to make the interior as spacious as possible and create the feeling of a mini superyacht. Despite its limited dimensions, the Vanton 45 has two spacious cabins. One for the owner with his/her partner and one for the children or guests.</p>
            <p className="my-vanton-section">Vanton yachts undeniably have the most interior space in their class. Especially in the spacious salon, you immediately experience the enormous space of this special motor yacht. With a 360 degree view all around and plenty of light, the outstanding spacious salon gives a feeling of luxury, freedom and wealth. Both in the salon and at the steering position where the skipper has a very good view everywhere. The large panoramic glass roof gives an extra dimension to the feeling of space.</p>
            <p className="my-vanton-section">Everywhere on the yacht you have the feeling of space. Unique for a yacht of this size.And this spaciousness continues throughout. From the salon you can reach the spacious foredeck, where comfort is reflected in luxurious sunbeds and a seating position from which you can look into the future.</p>
          </PageSection>
          <Arrow alignBottom={false} url="" />
          <PageSection id="comfort-luxury-and-safety-combined" image={image5} imageWP={image5WP} largeImage={largeImage5} largeImageWP={largeImage5WP} title="Comfort, luxury and safety combined" isReverse={true}>
            <p className="my-vanton-section">To bring comfort to the highest level, a lot of attention has been paid to reducing noise and vibrations. The soundproofing measures in and around the engine room ensure that normal conversations can be enjoyed even at higher speeds. With the minimum noise level in the interior, the journey can last forever!</p>
            <p className="my-vanton-section">The feeling of luxury is further enhanced by high-quality finishing, the choice of materials and the feeling of space that evokes the comparison with a penthouse on the water. A lot of attention has been paid to safety on the boat. There are no blind spots and safety glass is used.</p>
            <p className="my-vanton-section">When developing and building a Vanton Yacht, a lot of attention is paid to the feeling of ‘well being’ on board. For example, a Quick® zero speed stabilizer is fitted as standard. Mainly for extra safety, but of course also to prevent rolling and thus reduce the risk of seasickness.</p>            
          </PageSection>
          <Arrow alignBottom={false} url="" />
          <PageSection id="specifications" image={image6} imageWP={image6WP} largeImage={largeImage6} largeImageWP={largeImage6WP} title="Specifications">
            <p className="my-vanton-section">All Vanton yachts are built from high-quality steel or aluminiumand are crafted by hand. Steel and construction enables the perfect opportunity to completely arrange the interior of your motor yacht to your own needs and to style it to your personal taste. Vanton’s yacht architect is happy to take you on a journey!</p>
            <ul className="my-vanton-list">
              <li>Length: 13,3 m1</li>
              <li>Beam: 4,1m1</li>
              <li>Draught: 1m1</li>
              <li>Engine: 2x Volvo Penta D4-230</li>
              <li>Max speed: 13 knots</li>
              <li>Optional: 2x Volvo Penta D4-320</li>
              <li>Max speed: 17 knots</li>
              <li>Fuel: 800 litre</li>
              <li>Water: 600 litre</li>
              <li>Black water: 300 litre</li>
            </ul>
          </PageSection>
          <FormContainer topic="" pagePath="vanton" />
        </div>
      </Layout>
    </main>
  )
}

export default VantonPage;
